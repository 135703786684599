<template>
  <v-card class="tab-card centered-div-wrapper">
    <div class="centered-div text-center">
      <h1 class="mb-4">Berechnung</h1>
      <LoadingAnimation />
    </div>
  </v-card>
</template>

<script>
import LoadingAnimation from "./LoadingAnimation.vue";

export default {
  components: { LoadingAnimation },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.centered-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150%);
}
.centered-div-wrapper {
  position: relative;
}
</style>
