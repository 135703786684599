export default {
  data() {
    return {
      PerOrderItem: "Pro Bestellposition",
      ProRataOfOrderVolume: "% vom Bestellvolumen",
      MonthlyFlatRate: "Monatliche Pauschale",
      FlatRatePerDelivery: "Pauschale pro Lieferung",
      NoInformation: "k.A. über Artikelpreis",
    };
  },
  methods: {
    getLogisticsBillingTypes() {
      return [this.NoInformation, this.PerOrderItem, this.ProRataOfOrderVolume, this.MonthlyFlatRate, this.FlatRatePerDelivery];
    },
  },
};
