<template>
  <div class="mx-auto" style="width: 95%">
    <v-stepper v-model="tabIndex">
      <v-stepper-header class="elevation-0 grey lighten-3 pt-2">
        <v-stepper-step :complete="tabIndex > 1" step="1" color="primary"> Materialgruppen </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" color="primary"> Ergebnis </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="grey lighten-3 pt-3">
          <MaterialGroupInputTab ref="materialGroupInputTab" @calculated-event="completeCalc($event)" />
          <v-row class="mt-1"
            ><v-spacer></v-spacer><v-col class="text-right"> <v-btn class="white--text rounded-0" color="primary" @click="calculate()" v-on:keyup.enter="calculate()"> Berechnen </v-btn></v-col></v-row
          >
        </v-stepper-content>
        <v-stepper-content step="2" class="grey lighten-3 pt-3">
          <CalculatingTab v-if="calcAni"></CalculatingTab>
          <template v-else>
            <ResultTab ref="resultTab" :calcData="calcData" />
            <v-row class="mt-1"
              ><v-col class="text-left">
                <v-btn
                  class="rounded-0"
                  @click="
                    tabIndex = 1;
                    calcAni = true;
                  "
                  text
                  >Zurück</v-btn
                ></v-col
              ></v-row
            ></template
          >
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import MaterialGroupInputTab from "./MaterialGroupInputTab.vue";
import ResultTab from "./ResultTab.vue";
import CalculatingTab from "./CalculatingTab.vue";

export default {
  components: { MaterialGroupInputTab, ResultTab, CalculatingTab },
  data() {
    return {
      tabIndex: 1,
      calcAni: true,
      calcData: {
        overallAnnualOrderVolume: 0,
        overallAnnualOrderQuantity: 0,
        materialGroups: [],
      },
    };
  },
  methods: {
    calculate() {
      this.$refs.materialGroupInputTab.calc();
    },
    completeCalc(calcData) {
      this.calcData = calcData;
      this.tabIndex = 2;
      setTimeout(
        function () {
          this.calcAni = false;
        }.bind(this),
        3000
      );
    },
  },
};
</script>

<style>
.tab-card {
  min-height: 750px;
}
</style>
