class MaterialGroup {
  /* eslint-disable no-unused-vars */
  static logisticServiceBillingTypes = [
    {
      name: "k.A. über Artikelpreis",
      calcFunction: function (annualOrderQuantity, annualOrderVolume, logisticsServiceFrequency, logisticsServiceValue) {
        return 0;
      },
    },
    {
      name: "Pro Bestellposition",
      calcFunction: function (annualOrderQuantity, annualOrderVolume, logisticsServiceFrequency, logisticsServiceValue) {
        return annualOrderQuantity * logisticsServiceValue;
      },
    },
    {
      name: "% vom Bestellvolumen",
      calcFunction: function (annualOrderQuantity, annualOrderVolume, logisticsServiceFrequency, logisticsServiceValue) {
        return annualOrderVolume * (logisticsServiceValue / 100);
      },
    },
    {
      name: "Monatliche Pauschale",
      calcFunction: function (annualOrderQuantity, annualOrderVolume, logisticsServiceFrequency, logisticsServiceValue) {
        return logisticsServiceValue * 12;
      },
    },
    {
      name: "Pauschale pro Lieferung",
      calcFunction: function (annualOrderQuantity, annualOrderVolume, logisticsServiceFrequency, logisticsServiceValue) {
        return logisticsServiceValue * logisticsServiceFrequency;
      },
    },
  ];

  /* eslint-enable no-unused-vars */

  static getLogisticServiceBillingTypeByName(name) {
    for (var index = 0; index < MaterialGroup.logisticServiceBillingTypes.length; index++) {
      if (MaterialGroup.logisticServiceBillingTypes[index].name === name) {
        return MaterialGroup.logisticServiceBillingTypes[index];
      }
    }
    return null;
  }

  static logisticServiceFrequencies = [
    {
      name: "Keine",
      val: 0,
    },
    {
      name: "1x pro Monat",
      val: 12,
    },
    {
      name: "14-tägig",
      val: 24,
    },
    {
      name: "1x pro Woche",
      val: 52,
    },
    {
      name: "2x pro Woche",
      val: 104,
    },
    {
      name: "3x pro Woche",
      val: 156,
    },
    {
      name: "4x pro Woche",
      val: 208,
    },
    {
      name: "täglich",
      val: 220,
    },
  ];

  static getLogisticServiceFrequencyByName(name) {
    for (var index = 0; index < MaterialGroup.logisticServiceFrequencies.length; index++) {
      if (MaterialGroup.logisticServiceFrequencies[index].name === name) {
        return MaterialGroup.logisticServiceFrequencies[index];
      }
    }
    return null;
  }

  constructor(name, annualOrderVolumeRatio, supplierHandlingChargeRatio, logisticsServiceFrequency, logisticsBillingType, logisticsServiceValue) {
    this.name = name;
    this.annualOrderVolumeRatio = annualOrderVolumeRatio;
    this.annualOrderVolume = 0;
    this.annualOrderQuantity = 0;

    this.supplierHandlingChargeRatio = supplierHandlingChargeRatio;
    this.supplierHandlingCharge = 0;

    this.logisticsServiceFrequency = logisticsServiceFrequency;
    this.logisticsBillingType = logisticsBillingType;
    this.logisticsServiceValue = logisticsServiceValue;
    this.logisticsServiceCharge = 0;

    this.supplierHandlingAndLogisticsServiceCharge = 0;
    this.supplierHandlingAndLogisticsServiceChargeRatio = 0;
    this.annualOverallCost = 0;
  }

  preCalc(overallAnnualOrderQuantity, overallAnnualOrderVolume, inputByUser) {
    this.overallProcessCost = 0;
    this.actualSavings = 0;
    this.preCalcOrderInformation(overallAnnualOrderQuantity, overallAnnualOrderVolume, inputByUser);
    this.preCalcSupplierHandlingCharge();
  }

  preCalcOrderInformation(overallAnnualOrderVolume, overallAnnualOrderQuantity, inputByUser) {
    this.annualOrderVolume = overallAnnualOrderVolume * (this.annualOrderVolumeRatio / 100);
    if (!inputByUser) {
      this.annualOrderQuantity = overallAnnualOrderQuantity * (this.annualOrderVolumeRatio / 100);
    }
  }

  preCalcSupplierHandlingCharge() {
    this.supplierHandlingCharge = this.annualOrderVolume * (this.supplierHandlingChargeRatio / 100);
  }

  calc() {
    console.log("TTTTASDASDASD");
    let logisticServiceFrequencyVal = MaterialGroup.getLogisticServiceFrequencyByName(this.logisticsServiceFrequency).val;
    let logisticServiceBillingType = MaterialGroup.getLogisticServiceBillingTypeByName(this.logisticsBillingType);

    this.logisticsServiceCharge = logisticServiceBillingType.calcFunction(this.annualOrderQuantity, this.annualOrderVolume, logisticServiceFrequencyVal, this.logisticsServiceValue);

    this.supplierHandlingAndLogisticsServiceCharge = this.logisticsServiceCharge + this.supplierHandlingCharge;
    this.annualOverallCost = this.supplierHandlingAndLogisticsServiceCharge + this.annualOrderVolume;
    this.supplierHandlingAndLogisticsServiceChargeRatio = (this.supplierHandlingAndLogisticsServiceCharge / this.annualOrderVolume) * 100;
  }
}

export default MaterialGroup;
