export default {
  methods: {
    displayNumber: function (val, decPlaces, unit) {
      if (isNaN(val)) return;
      if (this.isFloat(val)) {
        return this.displayFloat(val, decPlaces, unit);
      }
      return this.displayInteger(val, decPlaces, unit);
    },
    displayInteger: function (val, decPlaces, unit) {
      let integerString = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (decPlaces > 0) {
        integerString = integerString.concat(",", "0".repeat(decPlaces));
      }
      if (unit != null && unit != "") {
        integerString = integerString.concat(" ", String(unit));
      }
      return integerString;
    },
    displayFloat: function (val, decPlaces, unit) {
      let floatSplitted = val.toFixed(decPlaces).toString().split(".");
      let floatBeforeDec = floatSplitted[0];
      let floatAfterDec = floatSplitted[1];
      let floatString = floatBeforeDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (!isNaN(floatAfterDec)) {
        floatString = floatString.concat(",", floatAfterDec);
      }
      if (unit != null && unit != "") {
        floatString = floatString.concat(" ", String(unit));
      }
      return floatString;
    },
    decodeDisplayedNumber(numberString) {
      numberString = numberString.replace(".", "");
      if (numberString.includes(",")) {
        numberString = numberString.replace(",", ".");
        return parseFloat(numberString);
      }
      return parseInt(numberString);
    },
    isFloat(value) {
      if (typeof value === "number" && !Number.isNaN(value) && !Number.isInteger(value)) {
        return true;
      }

      return false;
    },
  },
};
