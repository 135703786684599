export default {
  data() {
    return {
      None: "Keine",
      Every14Days: "14-tägig",
      OnceAMonth: "1x pro Monat",
      OnceAWeek: "1x pro Woche",
      TwiceAWeek: "2x pro Woche",
      ThreeTimesAWeek: "3x pro Woche",
      FourTimesAWeek: "4x pro Woche",
      Daily: "täglich",
    };
  },
  methods: {
    getLogisticsServiceFrequencys() {
      return [this.None, this.Every14Days, this.OnceAMonth, this.OnceAWeek, this.TwiceAWeek, this.ThreeTimesAWeek, this.FourTimesAWeek, this.Daily];
    },
  },
};
