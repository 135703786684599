<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="white--text rounded-0 mt-2 mr-2" color="primary" v-bind="attrs" v-on="on"> Download </v-btn>
    </template>
    <v-card>
      <v-card-title> PDF Download </v-card-title>
      <v-card-text>
        <p class="mb-0 mt-2">Bitte tragen Sie zunächst Ihre E-Mail-Adresse ein.</p>
        <v-text-field v-model="email" :rules="[rules.required, rules.email]"></v-text-field>
        <p class="mb-0 mt-2">Bitte stimmen Sie der Übermittlung der Daten zu.</p>
        <v-checkbox v-model="checkbox" :label="`Ich stimme zu`"></v-checkbox>
        <p class="text-justify">
          Ich willige ein, dass die P.S. Cooperation GmbH meine angegebenen Daten zum Zwecke der Bearbeitung meines PDF-Downloads verarbeiten darf. Eine Übermittlung meiner Daten an Dritte findet
          nicht statt und ist nicht geplant. Diese Einwilligung i. S. d. Art. 6 Abs. 1 lit. a) i. V. m. Art. 7 DSGVO kann jederzeit ohne Angabe von Gründen schriftlich widerrufen werden. Der Widerruf
          ist per Post an die P.S. Cooperation GmbH, Ravensberger Str. 47b, 33602 Bielefeld/Germany oder per E-Mail an: contact@ps-cooperation.com zu richten. Die Einwilligung ist freiwillig. Aus der
          Verweigerung der Einwilligung oder ihrem Widerruf entstehen keine Nachteile. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Die
          Datenschutzhinweise der P.S. Cooperation GmbH habe ich zur Kenntnis genommen.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          class="white--text rounded-0 mt-2 mx-auto"
          color="primary"
          text
          @click="
            dialog = false;
            emitDownloadButtonPressedEvent();
          "
          :disabled="btnDisabled"
        >
          E-Mail abschicken und PDF downloaden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      emailCheckPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      btnDisabled: true,
      checkbox: false,
      email: "",
      dialog: false,
      rules: {
        required: (value) => !!value || "Bitte ausfüllen.",
        counter: (value) => value.length <= 20 || "Maximal 20 Zeichen",
        email: (value) => {
          return this.emailCheckPattern.test(value) || "Invalide E-Mail-Adresse.";
        },
      },
    };
  },
  watch: {
    checkbox: function () {
      this.btnCheck();
    },
    email: function () {
      this.btnCheck();
    },
  },
  methods: {
    btnCheck() {
      if (this.checkbox && this.emailCheckPattern.test(this.email)) {
        this.btnDisabled = false;
      } else {
        this.btnDisabled = true;
      }
    },
    emitDownloadButtonPressedEvent() {
      console.log(this.resultData);
      this.$emit("download-button-pressed-event");
    },
  },
};
</script>

<style scoped></style>
