import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";

Vue.component("v-text-field", VTextField);

Vue.use(VCurrencyField, {
  locale: "pt-BR",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#7d024f",
        secondary: "#9a9a9",
        accent: "#8c8c8c",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
