<template>
  <v-card class="tab-card">
    <v-card-title>Materialgruppen</v-card-title>
    <v-card-subtitle>Bitte tragen Sie hier ihre Materialgruppenaufwände ein.</v-card-subtitle>
    <v-col class="py-1" xl="4" lg="6" sm="10">
      <table class="ml-2">
        <tr>
          <td class="pb-2 pr-3">
            <p class="mb-0 text-right">Anzahl Bestellpositionen p.a.</p>
          </td>
          <td class="pb-2">
            <v-text-field type="text" dense hide-details outlined v-model="overallAnnualOrderQuantityDisplay" @change="preCalc()">
              <template v-slot:append><span class="mt-1">Stk</span></template>
            </v-text-field>
          </td>
        </tr>
        <tr>
          <td class="pr-3">
            <p class="mb-0 text-right">Bestellvolumen in € p.a.</p>
          </td>
          <td>
            <v-currency-field dense outlined hide-details v-model="calcData.overallAnnualOrderVolume" @change="preCalc()"
              ><template v-slot:append><span class="mt-1">€</span></template>
            </v-currency-field>
          </td>
        </tr>
      </table>
    </v-col>
    <v-form ref="form" v-model="isFormValid">
      <v-simple-table fixed-header height="500px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center table-divider" colspan="4">Bestellinformationen</th>
              <th class="text-center table-divider" colspan="2">Handlingsaufschlag</th>
              <th class="text-center table-divider" colspan="3">Logistikdienstleistungen</th>
              <th></th>
            </tr>
            <tr>
              <th class="text-left">Materialgruppe</th>
              <th class="sm-td">Anteil Bestellvolumen</th>
              <th class="sm-td">Bestellvolumen p.a.</th>
              <th class="table-divider sm-td">Anzahl Bestellpositionen p.a.</th>
              <th class="sm-td">Aufschlag (%)</th>
              <th class="table-divider sm-td">Aufschlag (€)</th>
              <th class="lg-td">Abrechnungsart</th>
              <th class="lg-td">Lieferturnus</th>
              <th class="table-divider sm-td">Logistikkosten</th>
              <th class="text-center sm-td">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(materialGroup, index) in calcData.materialGroups" :key="index">
              <td>
                <v-text-field v-model="materialGroup.name">
                  <template v-slot:append>
                    <v-icon small> mdi-pencil </v-icon>
                  </template></v-text-field
                >
              </td>
              <td>
                <v-text-field v-model="materialGroup.annualOrderVolumeRatio" :error-messages="orderVolumeRatioFieldErrorMessage" :rules="orderVolumeInputRules" type="number" @change="preCalc()"
                  ><template v-slot:append>%</template>
                </v-text-field>
              </td>
              <td>
                {{ displayNumber(materialGroup.annualOrderVolume, 2, "€") }}
              </td>
              <td class="table-divider">
                <v-text-field
                  :error-messages="orderQuantityFieldErrorMessage"
                  v-model="materialGroup.annualOrderQuantity"
                  :rules="orderQuantityInputRules"
                  type="number"
                  @change="
                    inputByUser = true;
                    preCalc();
                  "
                  ><template v-slot:append>Stk</template>
                </v-text-field>
              </td>
              <td class="sm-td">
                <v-text-field v-model="materialGroup.supplierHandlingChargeRatio" type="number" @change="preCalc()"><template v-slot:append>%</template> </v-text-field>
              </td>
              <td class="table-divider">
                {{ displayNumber(materialGroup.supplierHandlingCharge, 2, "€") }}
              </td>
              <td>
                <v-select v-model="materialGroup.logisticsBillingType" :items="getLogisticServiceBillingTypeNames()"></v-select>
              </td>
              <td>
                <v-select
                  v-model="materialGroup.logisticsServiceFrequency"
                  :items="getLogisticsServiceFrequencys()"
                  :disabled="materialGroup.logisticsBillingType != 'Pauschale pro Lieferung'"
                ></v-select>
              </td>
              <td class="table-divider sm-td">
                <v-text-field v-model="materialGroup.logisticsServiceValue" type="number" :disabled="materialGroup.logisticsBillingType == 'k.A. über Artikelpreis'">
                  <template v-slot:append
                    ><span v-if="materialGroup.logisticsBillingType == 'k.A. über Artikelpreis'"></span><span v-else-if="materialGroup.logisticsBillingType == '% vom Bestellvolumen'">%</span
                    ><span v-else>€</span></template
                  ></v-text-field
                >
              </td>
              <td>
                <v-btn class="elevation-0" fab text small @click="deleteMaterialGroup(index)">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </td>
            </tr>
            <tr id="lastBtnTableElem" ref="lastBtnTableElem">
              <td colspan="10" class="text-center py-4">
                <v-btn fab dark small color="primary" @click="createNewMaterialGroup()">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>
  </v-card>
</template>

<script>
import MaterialGroup from "../materialgroup";
import LogisticsBillingTypes from "../logisticsBillingTypes";
import LogisticsServiceFrequencys from "../logisticsServiceFrequencys";
import utilsMixin from "../util.js";

export default {
  mixins: [LogisticsBillingTypes, LogisticsServiceFrequencys, utilsMixin],
  data() {
    return {
      orderQuantityFieldErrorMessage: "",
      orderVolumeRatioFieldErrorMessage: "",
      isFormValid: true,
      calcData: {
        overallAnnualOrderVolume: 0,
        overallAnnualOrderQuantity: 0,
        materialGroups: [],
      },
      inputByUser: false,
      orderQuantityInputRules: [(val) => val <= this.calcData.overallAnnualOrderQuantity || "Darf nicht größer sein als Jahresmenge", (val) => val >= 0 || "Ungültiger Wert"],
      orderVolumeInputRules: [(val) => (val >= 0 && val <= 100) || "Ungültiger Wert"],
    };
  },
  mounted() {
    this.createNewMaterialGroup();
  },
  computed: {
    overallAnnualOrderQuantityDisplay: {
      get: function () {
        return this.displayNumber(this.calcData.overallAnnualOrderQuantity, 0, "");
      },
      set: function (newValue) {
        this.calcData.overallAnnualOrderQuantity = this.decodeDisplayedNumber(newValue);
      },
    },
  },
  methods: {
    createNewMaterialGroup() {
      let newMaterialGroupName = "Materialgruppe " + (this.calcData.materialGroups.length + 1);
      this.calcData.materialGroups.push(new MaterialGroup(newMaterialGroupName, 0, 0, "Keine", "k.A. über Artikelpreis", 0));
    },
    deleteMaterialGroup(index) {
      this.calcData.materialGroups.splice(index, 1);
    },
    getLogisticServiceBillingTypeNames() {
      let names = [];
      for (var index = 0; index < MaterialGroup.logisticServiceBillingTypes.length; index++) {
        names.push(MaterialGroup.logisticServiceBillingTypes[index].name);
      }
      return names;
    },
    getLogisticServiceFrequenciesNames() {
      let names = [];
      for (var index = 0; index < MaterialGroup.logisticServiceFrequencies.length; index++) {
        names.push(MaterialGroup.logisticServiceFrequencies[index].name);
      }
      return names;
    },
    getCumulativeMaterialGroupOrderQuantity() {
      let cumulativeOrderQuantity = 0;
      for (var index = 0; index < this.calcData.materialGroups.length; index++) {
        let materialGroup = this.calcData.materialGroups[index];
        cumulativeOrderQuantity += parseInt(materialGroup.annualOrderQuantity);
      }
      return cumulativeOrderQuantity;
    },
    getCumulativeMaterialGroupOrderRatioVolume() {
      let cumulativeOrderVolumeRatio = 0;
      for (var index = 0; index < this.calcData.materialGroups.length; index++) {
        let materialGroup = this.calcData.materialGroups[index];
        cumulativeOrderVolumeRatio += parseFloat(materialGroup.annualOrderVolumeRatio);
      }
      return cumulativeOrderVolumeRatio;
    },
    preCalc() {
      this.constantValidation();
      for (var index = 0; index < this.calcData.materialGroups.length; index++) {
        let materialGroup = this.calcData.materialGroups[index];
        materialGroup.preCalc(this.calcData.overallAnnualOrderVolume, this.calcData.overallAnnualOrderQuantity, this.inputByUser);
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.orderQuantityFieldErrorMessage = "";
      this.orderVolumeRatioFieldErrorMessage = "";
    },
    constantValidation() {
      this.resetValidation();
      this.$refs.form.validate();

      if (this.getCumulativeMaterialGroupOrderQuantity() > this.calcData.overallAnnualOrderQuantity) {
        this.orderQuantityFieldErrorMessage = "Darf kumulativ nicht größer als Gesamtmenge sein";
        this.isFormValid = false;
      }
      if (this.getCumulativeMaterialGroupOrderRatioVolume() > 100) {
        this.orderVolumeRatioFieldErrorMessage = "Darf kumulativ nicht größer als 100% sein";
        this.isFormValid = false;
      }
    },
    finalValidation() {
      this.constantValidation();
      if (this.getCumulativeMaterialGroupOrderQuantity() < this.calcData.overallAnnualOrderQuantity) {
        this.orderQuantityFieldErrorMessage = "Gesamtbestellmenge noch nicht ausgereizt";
        this.isFormValid = false;
      }
      if (this.getCumulativeMaterialGroupOrderRatioVolume() < 100) {
        this.orderVolumeRatioFieldErrorMessage = "Gesamtbestellvolumen noch nicht ausgereizt";
        this.isFormValid = false;
      }
    },
    calc() {
      this.finalValidation();
      if (this.isFormValid) {
        this.preCalc();
        for (var index = 0; index < this.calcData.materialGroups.length; index++) {
          let materialGroup = this.calcData.materialGroups[index];
          materialGroup.calc();
        }
        this.$emit("calculated-event", this.calcData);
      }
    },
  },
};
</script>

<style scoped>
.table-divider {
  border-right: 2px solid #7d024f;
}
.no-table-border-bot {
  border-bottom: 0px solid;
}
td {
  text-align: center;
}
.sm-td {
  width: 120px;
}
.md-td {
  width: 170px;
}
.lg-td {
  width: 240px;
}
</style>
