<template>
  <v-card class="tab-card">
    <v-card-title>Einsparungen</v-card-title>
    <v-card-subtitle>Durch eine Optimierung durch el/ka/sy® ergeben sich folgende Einsparungen in ihren Materialgruppenaufwänden.</v-card-subtitle>
    <v-row>
      <v-col lg="7" md="12" class="px-4">
        <v-col class="py-2 mb-5" xl="7" lg="9" sm="12">
          <table class="ml-2">
            <tr>
              <td class="pb-2 pr-3">
                <p class="mb-0 text-right">Anzahl Bestellpositionen p.a.</p>
              </td>
              <td class="pb-2">
                <v-text-field dense disabled outlined hide-details :value="displayNumber(calcData.overallAnnualOrderQuantity, 0, ' ')"> {{}}</v-text-field>
              </td>
            </tr>
            <tr>
              <td class="pr-3">
                <p class="mb-0 text-right">Bestellvolumen in € p.a.</p>
              </td>
              <td>
                <v-text-field dense disabled outlined hide-details :value="displayNumber(calcData.overallAnnualOrderVolume, 2, '€')"> </v-text-field>
              </td>
            </tr>
          </table>
        </v-col>
        <v-simple-table class="table" dense fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Materialgruppe</th>
                <th>Bestellvolumen p.a.</th>
                <th>Anzahl Bestellpositionen p.a.</th>
                <th>Logistik- und Handlingsggebühr p.a. (€)</th>
                <th>Logistik- und Handlingsggebühr p.a. (%)</th>
                <th class="table-divider">Gesamtkosten p.a.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="materialGroup in calcData.materialGroups" :key="materialGroup.name">
                <td>{{ materialGroup.name }}</td>
                <td>
                  {{ displayNumber(materialGroup.annualOrderVolume, 2, "€") }}
                </td>
                <td>{{ displayNumber(materialGroup.annualOrderQuantity, 0, "") }}</td>
                <td>
                  {{ displayNumber(materialGroup.supplierHandlingAndLogisticsServiceCharge, 2, "€") }}
                </td>
                <td>
                  {{ displayNumber(materialGroup.supplierHandlingAndLogisticsServiceChargeRatio, 1, "%") }}
                </td>
                <td class="table-divider">
                  {{ displayNumber(materialGroup.annualOverallCost, 2, "€") }}
                </td>
              </tr>
              <tr>
                <td class="simple-table-result-line">Gesamt</td>
                <td class="simple-table-result-line">{{ displayNumber(overallAnnualOrderVolume, 2, "€") }}</td>
                <td class="simple-table-result-line">{{ displayNumber(overallAnnualOrderQuantity, 0, "") }}</td>
                <td class="simple-table-result-line">
                  {{ displayNumber(overallSupplierHandlingAndLogisticsServiceCharge, 2, "€") }}
                </td>
                <td class="simple-table-result-line">
                  {{ displayNumber(overallSupplierHandlingAndLogisticsServiceChargeRatio, 1, "%") }}
                </td>
                <td class="simple-table-result-line table-divider">
                  {{ displayNumber(overallAnnualOverallCost, 2, "€") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col lg="5" md="12" class="px-4">
        <SavingsChart :overallSavings="overallAnnualOverallCost * 0.67" :overallCostAfterSavings="overallAnnualOverallCost * 0.33"></SavingsChart>
        <p class="font-weight-bold mt-7 mb-0 pl-3 mb-2 pr-3 text-wrap custom-word-break">
          Wussten Sie schon, dass {{ displayNumber(overallSupplierHandlingAndLogisticsServiceChargeRatio, 0, "%") }} Ihrer Gesamtkosten für Handlings- und Logistik&shy;kosten entfallen?
        </p>
        <v-subheader class="font-weight-medium black--text pl-3">Über ein System für alles haben Sie endlich:</v-subheader>
        <v-col class="mx-auto pt-0" xl="8" lg="12" md="12">
          <v-list class="ml-3 pt-0" dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-currency-eur </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Transparente Preise für System, Logistik und Artikel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-arrow-up-bold </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Keine vermeidbaren Handlingsaufschläge</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-archive-arrow-up </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Keine verwässerten Artikelpreise mit Logistik, Software und Margen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-truck </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Alle Ihre Lieferanten</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <div class="d-flex justify-center">
          <v-btn target="_blank" href="https://www.ps-cooperation.de/demo-vereinbaren/" class="white--text rounded-0 mt-2 mr-2" color="primary">Beratung buchen</v-btn>
          <DownloadDlg @download-button-pressed-event="emitDownloadButtonPressedEvent()"></DownloadDlg>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SavingsChart from "./SavingsChart";
import DownloadDlg from "./DownloadDlg.vue";
import utilsMixin from "../util";

export default {
  components: { SavingsChart, DownloadDlg },
  props: ["calcData"],
  mixins: [utilsMixin],
  data() {
    return {
      overallSupplierHandlingAndLogisticsServiceCharge: 0,
      overallSupplierHandlingAndLogisticsServiceChargeRatio: 0,
      overallAnnualOverallCost: 0,
      overallAnnualOrderQuantity: 0,
      overallAnnualOrderVolume: 0,
      calculating: true,
    };
  },
  mounted() {
    this.calcOverall();
  },
  methods: {
    calcOverall() {
      let overallSupplierHandlingAndLogisticsServiceChargeVar = 0;
      let overallAnnualOrderQuantity = 0;
      let overallAnnualOrderVolume = 0;
      let overallAnnualOverallCostVar = 0;
      for (var index = 0; index < this.calcData.materialGroups.length; index++) {
        let materialGroup = this.calcData.materialGroups[index];
        console.log(materialGroup);
        overallSupplierHandlingAndLogisticsServiceChargeVar += materialGroup.supplierHandlingAndLogisticsServiceCharge;
        overallAnnualOverallCostVar += materialGroup.annualOverallCost;
        overallAnnualOrderVolume += materialGroup.annualOrderVolume;
        overallAnnualOrderQuantity += materialGroup.annualOrderQuantity;
      }
      this.overallSupplierHandlingAndLogisticsServiceCharge = overallSupplierHandlingAndLogisticsServiceChargeVar;
      this.overallAnnualOverallCost = overallAnnualOverallCostVar;
      this.overallAnnualOrderVolume = overallAnnualOrderVolume;
      this.overallAnnualOrderQuantity = overallAnnualOrderQuantity;
      this.overallSupplierHandlingAndLogisticsServiceChargeRatio = (overallSupplierHandlingAndLogisticsServiceChargeVar / overallAnnualOverallCostVar) * 100;
    },
  },
};
</script>

<style scoped>
.simple-table-result-line {
  border-top: 2px solid #7d024f;
}

.table-divider {
  border-left: 2px solid #7d024f;
}

.custom-word-break {
  hyphens: manual;
}

.table {
  min-height: 250px;
  max-height: 460px;
  overflow-y: auto;
}
</style>
