<template>
  <div>
    <bar-chart class="chart" ref="barChart" :chart-data="chartData" :options="chartOptions"></bar-chart>
  </div>
</template>

<script>
import BarChart from "./BarChart.js";
import utilsMixin from "../util";

export default {
  components: {
    BarChart,
  },
  mixins: [utilsMixin],
  props: ["overallSavings", "overallCostAfterSavings"],
  data() {
    return {
      chartData: {
        labels: ["Einsparpotenzial"],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        legend: {
          display: true,
          position: "bottom",
          reverse: true,
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {},
              stacked: true,
              barPercentage: 0.6,
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
      plugins: {},
    };
  },
  methods: {
    fillData() {
      this.chartData.datasets.push({
        label: "Verbleibende Kosten: " + String(this.displayNumber(this.overallCostAfterSavings, "2", "€")),
        data: [this.overallCostAfterSavings],
        backgroundColor: ["rgb(140, 140, 140)"],
        borderWidth: 0,
      });
      this.chartData.datasets.push({
        label: "Einsparungen: " + String(this.displayNumber(this.overallSavings, "2", "€")),
        data: [this.overallSavings],
        backgroundColor: ["rgb(125,2,79)"],
        borderWidth: 0,
      });
      this.$refs.barChart.rerender();
    },
  },
  watch: {
    overallCost: function () {
      this.fillData();
    },
    overallCostAfterSavings: function () {
      this.fillData();
    },
  },
};
</script>

<style>
.chart {
  height: 300px;
  position: relative;
}
</style>
